import { RefObject, useCallback, useEffect, useReducer, useRef, useState } from "react";
import { JSXElements, OnEventSetter, ReactTransitionStarter, TransitionState } from "../../types";
import styles from "../../css/dist/index.module.css";
import { checkEmailValidity, createUser, getCurrUser, postFPost, UserBlueprint } from "../../connections";
import { ErrorNotif } from "../util/errornotif";
import { VALIDITY_CHECKS } from "../../validity-checks";
import { redirect } from "../App";
import { User } from "../../../../lib";
import { excitingAccentButtonClassName } from "../util/classnames";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { Animated } from "react-animated-css";
import { BlurTextSetup } from "../util/gradienttext";
import { SingleFullInputStep } from "../util/inputtemplates";

export const SignUp = (
  {
    theme, updateTimeLeftForResendText, startTransition, setTransitionState, setPath,
    path, mainContainerRef, setCurrUser
  }: {
    theme: string,
    updateTimeLeftForResendText: (action: "REDUCE" | "RESET") => void,
    startTransition: ReactTransitionStarter, setTransitionState: OnEventSetter<TransitionState>,
    setPath: OnEventSetter<string>, path: string, mainContainerRef: RefObject<HTMLDivElement>,
    setCurrUser: (newCurrUser: User) => void
  }) => {
  const [errorText, setErrorText] = useState<string | undefined>();

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const uidInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInput1Ref = useRef<HTMLInputElement>(null);
  const passwordInput2Ref = useRef<HTMLInputElement>(null);
  const wantsTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const descriptionTextAreaRef = useRef<HTMLTextAreaElement>(null);
  
  const [loginMethod, setLoginMethod] = useState<"google" | "regular">();

  const validityCheck = useCallback(async (): Promise<[string, UserBlueprint | undefined]> => {
    if (!passwordInput1Ref.current || !passwordInput2Ref.current || !emptyDescCheckboxRef.current || !emptyWantsCheckboxRef.current) return ["An unexpected error occured. Please try again.", undefined];
    if (!usernameInputRef.current || !usernameInputRef.current.value) return ["Please enter a username.", undefined];
    if (!uidInputRef.current || !uidInputRef.current.value) return ["Please enter a user ID.", undefined];
    if (!wantsTextAreaRef.current || (!wantsTextAreaRef.current.value && !emptyWantsCheckboxRef.current.checked)) return ["Please enter your wants.", undefined];
    if (!descriptionTextAreaRef.current || (!descriptionTextAreaRef.current.value && !emptyDescCheckboxRef.current.checked)) return ["Please enter your description.", undefined];
    if (!emailInputRef.current || !emailInputRef.current.value) return ["Please enter a email.", undefined];

    if (!passwordInput1Ref.current && !passwordInput2Ref.current) return ["Please enter a password.", undefined];
    if (!passwordInput1Ref.current || !passwordInput1Ref.current.value || !passwordInput2Ref.current || !passwordInput2Ref.current.value) return ["Please type your password in both related fields.", undefined];
    if (passwordInput1Ref.current.value !== passwordInput2Ref.current.value) return ["Your password inputs are not the same.", undefined];  

    const userBlueprint: UserBlueprint = {
      uid: uidInputRef.current.value.trim() as string,
      username: usernameInputRef.current.value.trim() as string,
      email: emailInputRef.current.value.trim() as string,
      password: passwordInput1Ref.current.value as string,
      wants: emptyWantsCheckboxRef.current.checked ? "" : (wantsTextAreaRef.current.value.trim() as string),
      description: emptyDescCheckboxRef.current.checked ? "" : (descriptionTextAreaRef.current.value.trim() as string),
      type: 1,
      loginmethod: loginMethod??"regular",
    };

    // userProperty === component of property
    // shld have named those properties properties, not components :/// all g both make sense, screw standardized terminology
    for (let [userProperty, value] of Object.entries(userBlueprint)) {
      const uprp = userProperty as keyof UserBlueprint;
      // console.log(uprp);
      if (uprp === "type" || uprp === "loginmethod") continue;  // check unnecessary and/or already checked; checked earlier bc of diff format
      let errorResult;
      if (uprp === "password") {
        errorResult = await VALIDITY_CHECKS["password"](value, value);
      } else if (uprp === "description") {
        errorResult = await VALIDITY_CHECKS[uprp](value, emptyDescCheckboxRef.current?.checked ?? true);
        // console.log(emptyDescCheckboxRef.current?.checked ?? true, errorResult);
      } else if (uprp === "wants") {
        errorResult = await VALIDITY_CHECKS[uprp](value, emptyWantsCheckboxRef.current?.checked ?? true);
        // console.log(emptyWantsCheckboxRef.current?.checked ?? true, errorResult);
      } else {
        errorResult = await VALIDITY_CHECKS[uprp](value);
      }
      if (errorResult) return [errorResult, undefined];
    }

    return ["", userBlueprint];
  }, [loginMethod]);

  const [stepHistory, updateStepHistory] = useReducer((state: string[], action: ["ADD", string] | ["REMOVE"]): string[] => {
    if (action[0] === "ADD") {
      if (state.length > 100) return [...state.slice(1), action[1]];
      return [...state, action[1]];
    }
    return state.slice(0, -1);
  }, ["start"] as string[]);

  const [txtvisible, setTXTVisible] = useState("");
  const [visible, setVisible] = useState(true);
  const [busy, setBusy] = useState(false);
  const [step, _setStep] = useState("start");
  const updateStep = useCallback((nv: string, updateHistory?: boolean) => {
    if (busy) return;
    setBusy(true);

    if (updateHistory === undefined || updateHistory) updateStepHistory(["ADD", nv]);
    
    setTXTVisible(" ");
    setVisible(false);
    setTimeout(() => {
      _setStep(nv);
      setVisible(true);
      setBusy(false);
    }, 1000);
  }, [busy])
  
  const getGoBackFunction = useCallback(() => {
    return () => {
      const v = stepHistory.at(-2);
      if (!v) return;
      updateStep(v, false);
      updateStepHistory(["REMOVE"]);
    };
  }, [stepHistory, updateStep]);

  const onGoogleSignUpSuccess = useCallback(async (tokenresponse: TokenResponse) => {
    let dcd;
    try { 
      dcd = (await (await fetch(`https://oauth2.googleapis.com/tokeninfo?access_token=${tokenresponse.access_token}`, {
        method: "POST",
      })).json());
    } catch (e) {
      console.log(e);
      setErrorText("An unexpected error occured. Please try again.");
      return;
    }

    if (emailInputRef.current && passwordInput1Ref.current && passwordInput2Ref.current && dcd) {
      const eml = dcd.email as string;
      const sub = dcd.sub as string;
      if (!(await checkEmailValidity(eml))) {
        setErrorText("This email already has an account. Please sign in instead.");
        return;
      }
      emailInputRef.current.value = eml;
      passwordInput1Ref.current.value = sub;
      passwordInput2Ref.current.value = sub;
      // console.log(dcd);
      setLoginMethod("google");
      updateStep("username");
    } else {
      setErrorText("An unexpected error occured. Please try again.");
    }
  }, [updateStep]);

  const valdChk = useCallback(async (therrs: boolean, vc: (...args: any[]) => string | Promise<string>, ...refs: RefObject<HTMLInputElement | HTMLTextAreaElement>[]) => {
    for (const ref of refs) {
      if (!ref.current) {
        const etx = "An unexpected error occured. Please try again.";
        if (therrs) setErrorText(etx);
        return etx;
      }
    }
    let rs;
    if (vc === VALIDITY_CHECKS["description"]) {
      rs = await vc(refs[0].current?.value as string, emptyDescCheckboxRef.current?.checked ?? true);
    } else if (vc === VALIDITY_CHECKS["wants"]) {
      rs = await vc(refs[0].current?.value as string, emptyWantsCheckboxRef.current?.checked ?? true);
    } else {
      rs = await vc(...refs.map((v) => { return v.current?.value as string; }));
    }
    if (rs === "") return "";
    if (therrs) setErrorText(rs);
    return rs;
  }, []);

  const [valdBusy, setValdBusy] = useState(false);
  const [currOnInput, setCurrOnInput] = useState<() => void>(() => () => { });
  useEffect(() => {
    let baseF = async () => {};
    switch (step) {
      case "username":
        baseF = async () => { setTXTVisible(await valdChk(false, VALIDITY_CHECKS["username"], usernameInputRef)); };
        break;
      case "uid":
        baseF = async () => { setTXTVisible(await valdChk(false, VALIDITY_CHECKS["uid"], uidInputRef)); };
        break;
      case "password":
        baseF = async () => { setTXTVisible(await valdChk(false, VALIDITY_CHECKS.password, passwordInput1Ref, passwordInput2Ref)); };
        break;
      case "email":
        baseF = async () => { setTXTVisible(await valdChk(false, VALIDITY_CHECKS["email"], emailInputRef)); };
        break;
      case "wants":
        baseF = async () => { setTXTVisible(await valdChk(false, VALIDITY_CHECKS["wants"], wantsTextAreaRef)); };
        break;
      case "description":
        baseF = async () => { setTXTVisible(await valdChk(false, VALIDITY_CHECKS["description"], descriptionTextAreaRef)); };
        break;
    }
    setCurrOnInput(() => async () => { setValdBusy(true); await baseF(); setValdBusy(false); })
  }, [step, valdChk]);

  useEffect(() => {
    currOnInput();
  }, [currOnInput]);

  const [attemptingSignUp, setAttemptingSignUp] = useState(false);

  const failAttempt = useCallback(() => {
    updateStep("start");
    setAttemptingSignUp(false);
    return false;
  }, [updateStep]);

  const [postUser, setPostUser] = useState(true);
  const attemptSignUp = useCallback(async () => {
    if (attemptingSignUp) {
      failAttempt();
      return;
    }

    setAttemptingSignUp(true);

    const [errorResult, userBlueprint] = await validityCheck();
    if (errorResult) {
      failAttempt();
      setErrorText(errorResult);
      return;
    }
    if (!userBlueprint) {
      failAttempt();
      setErrorText("Something unexpected happened... please try signing up again.");
      return;
    }
    
    createUser(userBlueprint).then((cuSuccess) => {
      if (!cuSuccess) {
        failAttempt();

        if (cuSuccess === undefined) {
          setErrorText("Something unexpected happened... please try signing up again.");
        }
        setErrorText("Invalid email address");

        return;
      }
      
      updateTimeLeftForResendText("RESET");
      getCurrUser().then((user) => {
        if (!user) {
          redirect(startTransition, "/signin", "maskRL", "maskRL", setTransitionState, setPath);
          return;
        }
        
        if (
          postUser &&
          !(emptyDescCheckboxRef.current?.checked ?? true) &&
          !(emptyWantsCheckboxRef.current?.checked ?? true)
        )
          postFPost();

        setCurrUser(user);
        redirect(startTransition, "/upload", "maskTB", "maskTB", setTransitionState, setPath);
      });
    });

    return;
  }, [attemptingSignUp, failAttempt, validityCheck, setCurrUser, setPath, setTransitionState, startTransition, updateTimeLeftForResendText, postUser]);

  const tryGoogleSignUp = useGoogleLogin({
    onSuccess: onGoogleSignUpSuccess,
    onError: () => { setErrorText("An unexpected error occured, please try again.") },
  });

  const emptyDescCheckboxRef = useRef<HTMLInputElement>(null);
  const emptyWantsCheckboxRef = useRef<HTMLInputElement>(null);

  const descriptionChangeCallback = useCallback(() => {
    if (descriptionTextAreaRef.current) {
      if (emptyDescCheckboxRef.current?.checked) valdChk(true, VALIDITY_CHECKS["description"], descriptionTextAreaRef);
      descriptionTextAreaRef.current.disabled = emptyDescCheckboxRef.current?.checked ?? true;
    }
  }, [valdChk]);
  const wantsChangeCallback = useCallback(() => {
    if (wantsTextAreaRef.current) {
      if (emptyWantsCheckboxRef.current?.checked) valdChk(true, VALIDITY_CHECKS["wants"], wantsTextAreaRef);
      wantsTextAreaRef.current.disabled = emptyWantsCheckboxRef.current?.checked ?? true;
    }
  }, [valdChk]);

  useEffect(() => {
    if (emptyDescCheckboxRef.current) {
      emptyDescCheckboxRef.current.removeEventListener("change", descriptionChangeCallback);
      emptyDescCheckboxRef.current.addEventListener("change", descriptionChangeCallback);
    }
    if (emptyWantsCheckboxRef.current) {
      emptyWantsCheckboxRef.current.removeEventListener("change", wantsChangeCallback);
      emptyWantsCheckboxRef.current.addEventListener("change", wantsChangeCallback);
    }
  }, [descriptionChangeCallback, wantsChangeCallback]);

  return (
    <div className={`${styles["w-full"]} ${styles["h-full"]} ${styles["flex"]} ${styles["items-center"]} ${styles["justify-center"]}`}>
      <div className={`${styles["mcbg"]} ${styles["fixed"]} ${styles["w-full"]} ${styles["h-full"]}`}></div>
      <div className={`${styles["fixed"]} ${styles["w-full"]} ${styles["h-full"]} ${styles["bg-base-100"]} ${styles["opacity-65"]}`}></div>

      <div className={`${styles["wave"]}`}></div>
      <div className={`${styles["wave"]}`}></div>
      <div className={`${styles["wave"]}`}></div>

      <Animated animationIn="fadeInUp" animationOut="fadeOutDown" isVisible={visible} className={`${styles["lg:text-lg"]} ${styles["relative"]} ${styles["m-12"]} ${styles["w-full"]} ${styles["max-w-6xl"]}`}>
        <div className={`${step !== "start" ? styles["hidden"] : ""} ${styles["w-full"]} ${styles["h-full"]} ${styles["flex"]} ${styles["gap-4"]} ${styles["items-center"]} ${styles["justify-center"]}`}>
          <SingleFullInputStep errorText={""} onErrorClose={() => {}} label={"Sign Up"}>
            <button
              onClick={ () => { tryGoogleSignUp(); } }
              className={`${styles["lg:text-lg"]} ${styles["btn"]} ${styles["h-16"]} ${styles["w-full"]} ${styles["btn-primary"]} ${styles["transition-opacity"]}`}>
              Join with Google
              <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" alt=""></img>
            </button>
            <button
              onClick={ () => { setLoginMethod("regular"); updateStep("username"); } }
              className={`${styles["lg:text-lg"]} ${styles["btn"]} ${styles["h-16"]} ${styles["w-full"]} ${styles["btn-neutral"]} ${styles["drop-shadow-neutral"]} ${styles["transition-opacity"]}`}>
              Skip {">"}
            </button>
          </SingleFullInputStep>
        </div>
        <SignUpStep valdBusy={valdBusy} goBack={getGoBackFunction()} step={step} title={"username"} onContinueClick={ async () => { if (await valdChk(true, VALIDITY_CHECKS["username"], usernameInputRef) === "") updateStep("uid"); } } txtvisible={txtvisible}>
          <input
            onInput={ currOnInput }
            placeholder="Username"
            type="text"
            className={`${styles["input"]} ${styles["input-lg"]} ${styles["leading-[30rem]"]} ${styles["w-full"]}`}
            ref={usernameInputRef}
          ></input>
        </SignUpStep>
        <SignUpStep valdBusy={valdBusy} subtitle="Your user identification is a unique identifier." goBack={getGoBackFunction()} step={step} title={"uid"} onContinueClick={ async () => { if (await valdChk(true, VALIDITY_CHECKS.uid, uidInputRef) === "") updateStep(loginMethod === "regular" ? "password" : "wants"); } } txtvisible={txtvisible}>
          <input
            onInput={ currOnInput }
            type="text"
            placeholder="User ID"
            autoComplete="username"
            className={`${styles["input"]} ${styles["input-lg"]} ${styles["leading-[30rem]"]} ${styles["w-full"]}`}
            ref={uidInputRef}
          ></input>
        </SignUpStep>
        <SignUpStep valdBusy={valdBusy} goBack={getGoBackFunction()} step={step} title={"password"} onContinueClick={ async () => { if (await valdChk(true, VALIDITY_CHECKS["password"], passwordInput1Ref, passwordInput2Ref) === "") updateStep("email"); } } txtvisible={txtvisible}>
          <input
            onInput={ currOnInput }
            placeholder="Password"
            type="password"
            className={`${styles["input"]} ${styles["input-lg"]} ${styles["leading-[30rem]"]} ${styles["w-full"]}`}
            ref={passwordInput1Ref}
          ></input>
          <input
            onInput={ currOnInput }
            placeholder="Confirm Password"
            type="password"
            className={`${styles["input"]} ${styles["input-lg"]} ${styles["leading-[30rem]"]} ${styles["w-full"]}`}
            ref={passwordInput2Ref}
          ></input>
        </SignUpStep>
        <SignUpStep valdBusy={valdBusy} subtitle="Your email will be public." goBack={getGoBackFunction()} step={step} title={"email"} onContinueClick={ async () => { if (await valdChk(true, VALIDITY_CHECKS["email"], emailInputRef) === "") updateStep("wants"); } } txtvisible={txtvisible}>
          <input
            onInput={ currOnInput }
            placeholder="Email"
            type="email"
            className={`${styles["input"]} ${styles["input-lg"]} ${styles["leading-[30rem]"]} ${styles["w-full"]}`}
            ref={emailInputRef}
          ></input>
        </SignUpStep>
        <SignUpStep valdBusy={valdBusy} goBack={getGoBackFunction()} subtitle={<>Describe your ideal partner, and Badavas will find your pick. Try to the specify your ideal partner's <b>location</b> (town, province, state), <b>size</b>, <b>product</b>, <b>industry</b>, and <b>specialty</b> (50-256 characters).</>} step={step} title={"wants"} onContinueClick={ async () => { if (await valdChk(true, VALIDITY_CHECKS["wants"], wantsTextAreaRef) === "") updateStep("description"); } } txtvisible={txtvisible}>
          <textarea
            onInput={ currOnInput }
            className={`${styles["textarea"]} ${styles["min-h-40"]} ${styles["w-full"]}`}
            ref={wantsTextAreaRef}
          ></textarea>
          <div className={
            `${styles["w-full"]}
            ${styles["text-neutral-content"]} ${styles["rounded"]}
            ${styles["p-2"]} ${styles["justify-center"]}
            ${styles["flex"]} ${styles["gap-4"]} ${styles["flex-wrap"]} ${styles["items-center"]}`
          }>
            Leave empty (you will not be discoverable on the site)
            <input
              onChange={ currOnInput }
              ref={emptyWantsCheckboxRef} type="checkbox"
              className={`${styles["checkbox"]} ${styles["checkbox-secondary"]}`}
            />
          </div>
        </SignUpStep>
        <SignUpStep valdBusy={valdBusy} goBack={getGoBackFunction()} subtitle={<>Describe your brand. For best results, include your <b>location</b> (town, province, state), <b>size</b>, <b>product</b>, <b>industry</b>, and <b>specialties</b> (50-256 characters).</>} step={step} title={"description"} onContinueClick={ async () => { if (await valdChk(true, VALIDITY_CHECKS["description"], descriptionTextAreaRef) === "") updateStep("final"); } } txtvisible={txtvisible}>
          <textarea
            onInput={ currOnInput }
            className={`${styles["textarea"]} ${styles["min-h-40"]} ${styles["w-full"]}`}
            ref={descriptionTextAreaRef}
          ></textarea>
          <div className={
            `${styles["w-full"]}
            ${styles["text-neutral-content"]} ${styles["rounded"]}
            ${styles["p-2"]} ${styles["justify-center"]}
            ${styles["flex"]} ${styles["gap-4"]} ${styles["flex-wrap"]} ${styles["items-center"]}`
          }>
            Leave empty (you will not be discoverable on the site)
            <input
              onChange={ currOnInput }
              ref={emptyDescCheckboxRef} type="checkbox"
              className={`${styles["checkbox"]} ${styles["checkbox-secondary"]}`}
            />
          </div>
        </SignUpStep>
        <SignUpStep valdBusy={valdBusy} goBack={getGoBackFunction()} subtitle={((emptyDescCheckboxRef.current?.checked ?? true) || (emptyWantsCheckboxRef.current?.checked ?? true)) ? "Are you ready?" : "Would you like us to post your profile once you sign up?"} step={step} title={"final"} onContinueClick={ () => { updateStep("pendingcompletion"); attemptSignUp(); } } txtvisible={""}>
          {
            ((emptyDescCheckboxRef.current?.checked ?? true) || (emptyWantsCheckboxRef.current?.checked ?? true)) ? null :
            <>
              <button className={`
                ${styles["btn"]} ${styles["border"]}
                ${styles["hover:!border-neutral-content"]}
                ${postUser ? styles["border-neutral-content"] : styles["border-neutral"]}
                ${styles["w-1/2"]}
              `} onClick={ () => { setPostUser(true); } }>Yes</button>
              <button className={`
                ${styles["btn"]} ${styles["border"]}
                ${styles["hover:!border-neutral-content"]}
                ${!postUser ? styles["border-neutral-content"] : styles["border-neutral"]}
                ${styles["w-1/2"]}
              `} onClick={ () => { setPostUser(false); } }>No</button>
            </>
          }
        </SignUpStep>
        {
          step === "pendingcompletion"
          ? (<div className={`${styles["flex"]} ${styles["flex-col"]} ${styles["items-center"]} ${styles["w-full"]} ${styles["gap-4"]}`}>
            <BlurTextSetup>
              <div className={`${styles["font-bold"]} ${styles["font-inter"]} ${styles["text-[8rem]"]} ${styles["leading-[8rem]"]} ${styles["bg-clip-text"]} ${styles["bg-gradient-to-tr"]} ${styles["text-transparent"]} ${theme==="dark" ? styles["bg-accent"] : styles["bg-neutral-content"]} ${styles["block"]} ${styles["animate-bounce"]}`}>B.</div>
            </BlurTextSetup>
          </div>) : null
        }
      </Animated>

      <ErrorNotif errorText={errorText} onClose={() => { setErrorText(undefined); }}></ErrorNotif>
    </div>
  );
};

const SignUpStep = (
  { step, goBack, title, subtitle, children, onContinueClick, txtvisible, valdBusy }: {
    valdBusy: boolean, step: string, goBack?: () => void, title: string, subtitle?: JSXElements, children: JSXElements, onContinueClick: () => void, txtvisible: string
  }
) => {
  const continueButtonRef = useRef<HTMLButtonElement>(null);
  const [shifted, setShifted] = useState(false);

  return (
    <div
      className={`${step !== title ? styles["invisible"] : styles["visible"]} ${styles["absolute"]} ${styles["w-full"]} ${styles["h-full"]} ${styles["flex"]} ${styles["flex-col"]} ${styles["gap-4"]} ${styles["items-center"]} ${styles["justify-center"]}`}
      onKeyUp={(e) => {
        if (e.key === "Shift") setShifted(false);
        if (e.key === "Enter" && !shifted && step === title) continueButtonRef?.current?.click();
      }}
      onKeyDown={(e) => {
        if (e.key === "Shift") setShifted(true);
      }}
    >
      { subtitle ? (<div className={`${styles["text-center"]}`}>
        { subtitle }
      </div>) : null }

      { children }

      <div className={`${styles["flex"]} ${styles["w-full"]} ${styles["justify-center"]} ${styles["gap-4"]} ${styles["flex-warp"]}`}>
        { goBack ? (
          <button
            className={`${styles["hover:pointer"]}`}
            onClick={goBack}
          >{"<<"} Back</button>
        ) : null }
        <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={txtvisible === ""}>
          {
            step === "final"
            ? (
              <button
                className={`${excitingAccentButtonClassName}`}
                onClick={valdBusy && txtvisible === "" ? () => {} : onContinueClick}
                ref={continueButtonRef}
              >Join Badavas!</button>
            ) : (
              <button
                onClick={valdBusy && txtvisible === "" ? () => {} : onContinueClick}
                ref={continueButtonRef}
              >Continue {">>"}</button>
            )
          }
        </Animated>
      </div>

      <span className={`${styles["text-sm"]} ${styles["min-h-6"]} ${styles["min-w-1"]} ${styles["text-center"]} ${styles["block"]}`}>{txtvisible.slice(-3) === "..." ? "" : txtvisible}</span>
    </div>
  );
};
